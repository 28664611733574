import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
// import devDebug from "../../../../Helpers/debug";
import { getQueryLogs } from "../SummaryResultsModal/Utility/utility";
import { useVademecumStore } from "../../../../../Stores/vademecumStore";
import { useAuth } from "../../../../../Services/auth";
import { attemptFormatting, QueryHistoryItemLogModalItem } from "./QueryHistoryItemLogModalItem";
import TenderTitleComponent from "../SummaryResultsModal/Components/tenderTitleComponent";
import Icon from "@mdi/react";
import { mdiOpenInNew } from "@mdi/js";

export default function QueryHistoryItemLogModal({ editTenderOrVademecum }) {
  const { t } = useTranslation();
  const auth = useAuth();

  React.useEffect(() => {
    getQueryLogs(auth);
  }, []);

  const [
    queryHistoryItemLogStatus,
    queryHistoryItemLogModalVisible,
    setQueryHistoryItemLogModalVisible,
    queryHistoryItemLog,
    queryHistoryItemLogToBeShown,
    queryHistoryItemLogName,
  ] = useVademecumStore((state) => [
    state.queryHistoryItemLogStatus,
    state.queryHistoryItemLogModalVisible,
    state.setQueryHistoryItemLogModalVisible,
    state.queryHistoryItemLog,
    state.queryHistoryItemLogToBeShown,
    state.queryHistoryItemLogName,
  ]);

  return (
    <Modal
      isOpen={queryHistoryItemLogModalVisible}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="tc-screen-width-modal"
      tabIndex="-1"
      zIndex="11"
      toggle={() => {
        setQueryHistoryItemLogModalVisible(!queryHistoryItemLogModalVisible);
      }}
    >
      <ModalHeader
        tag="h1"
        className="ta-modals-header"
        toggle={() => {
          setQueryHistoryItemLogModalVisible(!queryHistoryItemLogModalVisible);
        }}
      >
        <div className="d-flex flex-column">
          <div className="tc-modal-header">{`${t(
            "Logs for query"
          )}: ${queryHistoryItemLogToBeShown} (${queryHistoryItemLogName})`}</div>
          <TenderTitleComponent />
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="tc-template-modal-max-height-tallest tc-text-muted">
          {queryHistoryItemLogStatus === "loading" ? (
            <Spinner></Spinner>
          ) : (
            <div className="d-flex flex-column tc-flex-gap-10px">
              <div className="fs-1 me-auto ms-auto fw-bold ">{t("Summary")}</div>
              <div className="d-flex flex-column flex-md-row tc-flex-gap-10px">
                <div className="d-flex flex-column tc-flex-gap-10px">
                  <div className="d-flex flex-column tc-flex-gap-10px">
                    <div className="fw-bold">{t("QA Prompt")}:</div>
                    <div>{queryHistoryItemLog?.summary?.qa_prompt}</div>
                  </div>
                  <div className="d-flex flex-column tc-flex-gap-10px">
                    <div className="fw-bold">{t("Query Key")}:</div>
                    <div>{queryHistoryItemLog?.summary?.query_key}</div>
                  </div>
                </div>
                <div className="d-flex flex-column tc-flex-gap-10px">
                  <div className="d-flex flex-column tc-flex-gap-10px">
                    <div className="fw-bold">{t("Prompt")}:</div>
                    <div>{queryHistoryItemLog?.summary?.prompt}</div>
                  </div>
                  <div className="d-flex flex-column tc-flex-gap-10px">
                    <div className="fw-bold">{t("Response")}:</div>
                    <div className="tc-max-height-200px">
                      {attemptFormatting("response_body", queryHistoryItemLog?.summary?.response_body)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="fs-1 me-auto ms-auto fw-bold mt-4">{t("Documents")}</div>
              <div className="d-flex flex-column tc-flex-gap-10px">
                {queryHistoryItemLog?.summary?.source_documents ? (
                  queryHistoryItemLog?.summary?.source_documents.map((doc, index) => (
                    <div className="d-flex flex-column tc-flex-gap-5px" key={index}>
                      <a
                        target="_blank"
                        className="fw-bold tc-purple-text text-decoration-underline cursor-pointer fs-5 d-flex align-items-start tc-flex-gap-5px mt-2"
                        href={doc?.metadata?.source}
                        rel="noreferrer"
                      >
                        {`Document ${index}`}
                        <Icon
                          path={mdiOpenInNew}
                          size={0.7}
                          className="tc_grow_on_hover_icon cursor-pointer mt-1"
                          title={t("Go to document (external link)")}
                        />
                      </a>
                      <div className="d-flex tc-flex-gap-10px">
                        <div className="fw-bold">{`Name: `}</div>
                        <div>{doc?.metadata?.name}</div>
                      </div>
                      <div className="d-flex tc-flex-gap-10px">
                        <div className="fw-bold">{`Tags: `}</div>
                        <div>{doc?.metadata?.tags}</div>
                      </div>
                      <div className="tc-template-modal-max-height-200px">{doc?.page_content}</div>
                      <a target="_blank" href={doc?.metadata?.source} rel="noreferrer">
                        {doc.metadata.source}
                      </a>
                    </div>
                  ))
                ) : (
                  <div>{t("There were no source documents")}</div>
                )}
              </div>
              <div className="fs-1 me-auto ms-auto fw-bold mt-4">{t("Log")}</div>
              {queryHistoryItemLog?.logs?.map((item, idx) => (
                <QueryHistoryItemLogModalItem key={idx} item={item} />
              ))}
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button
          className="tc-full-width-small"
          onClick={() => {
            setQueryHistoryItemLogModalVisible(false);
          }}
          type="button"
          color="danger"
        >
          {t("Back")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
