import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useViewEditTenderStore } from "../../../../Stores/viewEditTender";
import { getTokenAndFirstTimeFromLocalStorage, useAuth } from "../../../../Services/auth";
// import devDebug from "../../../../Helpers/debug";
import { useVademecumStore } from "../../../../Stores/vademecumStore";
// import cloneDeep from "lodash/cloneDeep";
import { ReactSortable } from "react-sortablejs";
import { mdiArrowAll } from "@mdi/js";
import Icon from "@mdi/react";
import {
  // getSummaries,
  getTemplates,
} from "../../2_UtilityFunctions/viewTenderUtilities";
import { AsyncPaginate } from "react-select-async-paginate";

export default function FieldSelectionWizardModal({ submitCapability, isAdminRoute }) {
  const { t, i18n } = useTranslation();
  const auth = useAuth();
  const [search, setSearch] = React.useState("");
  const [summaryWizardTab, setSummaryWizardTab] = React.useState("search");
  // const [requestStatus, setRequestStatus] = React.useState("none");
  const [requestError, setRequestError] = React.useState(null);
  const blockRef = React.useRef(false);

  const [savingStatus, setSavingStatus] = React.useState("none");
  const [savingError, setSavingError] = React.useState(null);
  const apiService = auth.apiService;
  const [
    fields,
    fieldSelectionWizardModalVisible,
    setFieldSelectionWizardModalVisible,
    setSummaryTemplateModalVisible,
  ] = useViewEditTenderStore((state) => [
    state.fields,
    state.fieldSelectionWizardModalVisible,
    state.setFieldSelectionWizardModalVisible,
    state.setSummaryTemplateModalVisible,
  ]);
  const [createOrEditMode, templateToBeEdited, setTemplateName, setSelectedTemplateFields, setUsersAssignedToTemplate] =
    useVademecumStore((state) => [
      state.createOrEditMode,
      state.templateToBeEdited,
      state.setTemplateName,
      state.setSelectedTemplateFields,
      state.setUsersAssignedToTemplate,
    ]);

  const defaultAdditional = {
    page: 1,
  };

  const loadPageOptions = async (q, prevOptions, { page }) => {
    const tokensAndFirstTime = getTokenAndFirstTimeFromLocalStorage();
    const resp = await fetch(
      // `${process.env.REACT_APP_BASEURL}/UAadmin/tendering-authority/?search=${q}&page=${page}`
      `${process.env.REACT_APP_BASEURL}/UAadmin/users/?page_size=max&search=${q}&page=${page}`,
      {
        headers: {
          Authorization: `JWT ${tokensAndFirstTime.access}`,
        },
      }
    ).then((res) => res.json());

    return {
      options: resp.results.map((user) => ({
        value: user.email,
        label: user.email,
      })),
      hasMore: resp.links.next ? true : false,

      additional: {
        page: page + 1,
      },
    };
  };

  const [filteredFields, setFilteredFields] = React.useState(fields);

  const postUserTemplateSuccess = (resp, data) => {
    const controller = new AbortController();
    getTemplates(controller, true, auth);
    setSavingStatus("success");
    setTimeout(() => {
      setSavingStatus("none");
      if (!blockRef.current) {
        setFieldSelectionWizardModalVisible(false);
      } else {
        setRequestError(t("There was an error requesting the summary, but your template was saved."));
      }

      if (!data.payload.submittedRequest) {
        setSummaryTemplateModalVisible(true);
      }
    }, 2000);
  };

  const postUserTemplateError = () => {
    setSavingError("There was an error saving the template");
    setSavingStatus("error");
    setTimeout(() => {
      setSavingStatus("none");
    }, 2000);
  };

  const saveTemplate = (submittedRequest) => {
    if (!submittedRequest) {
      setSavingStatus("saving");
    }

    const controller = new AbortController();
    const data = {};

    let hasPositions = false;

    for (let field of useVademecumStore.getState().templateToBeEdited.fields) {
      if (field.position !== 0) {
        hasPositions = true;
        break;
      }
    }

    if (!hasPositions) {
      data.fields = useVademecumStore.getState().templateToBeEdited.fields.map((field, idx) => ({
        field_id: field.field.id,
        position: idx,
      }));
    } else {
      data.fields = useVademecumStore.getState().templateToBeEdited.fields.map((field) => ({
        field_id: field.field.id,
        position: field.position,
      }));
    }

    data.name = useVademecumStore.getState().templateToBeEdited.name;
    data.template_id = useVademecumStore.getState().templateToBeEdited.id;
    data.users = useVademecumStore.getState().templateToBeEdited.users;
    data.submittedRequest = submittedRequest;

    if (useVademecumStore.getState().createOrEditMode === "create") {
      apiService.axiosPOST(
        `vademecum/templates/`, //endpoint
        controller.signal, //signal
        postUserTemplateSuccess, //successFn
        auth.newErrorHandler, //error AlerterFn
        "There was an error saving the template", //error message
        postUserTemplateError, //errorFn (gets error back)
        {
          section: "View / Edit Tender -> Summary Tab -> save template",
          payload: data,
        } //data (gets handed back to success fn)
      );
    } else {
      // let premade = useVademecumStore.getState().templateToBeEdited.premade;
      data.id = useVademecumStore.getState().templateToBeEdited.id;

      apiService.axiosPATCH(
        // `vademecum/${premade ? "premade-" : ""}templates/${data.id}/`, //endpoint
        `vademecum/templates/${data.id}/`, //endpoint
        controller.signal, //signal
        postUserTemplateSuccess, //successFn
        auth.newErrorHandler, //error AlerterFn
        "There was an error editing the template", //error message
        postUserTemplateError, //errorFn (gets error back)
        {
          section: "View / Edit Tender -> Summary Tab -> edit template",
          payload: data,
        } //data (gets handed back to success fn)
      );
    }
  };

  // const submitRequestSuccess = (resp) => {
  //   setRequestStatus("success");
  //   const controller = new AbortController();
  //   getSummaries(controller, auth, isAdminRoute, true); //controller, auth, isAdminRoute, specificTenderID
  //   setTimeout(() => {
  //     setRequestStatus("none");
  //     setFieldSelectionWizardModalVisible(false);
  //   }, 2000);
  // };
  // const submitRequestError = () => {
  //   setRequestError(t("There was an error requesting the summary"));
  //   setRequestStatus("error");
  //   blockRef.current = true;
  //   setTimeout(() => {
  //     setRequestStatus("none");
  //   }, 2000);
  // };

  // const submitRequest = () => {
  //   const user = useViewEditTenderStore.getState().user;
  //   const selectedDocuments =
  //     useViewEditTenderStore.getState().selectedDocuments;
  //   blockRef.current = false;

  //   const summaryRequestBody = {
  //     fields: useVademecumStore
  //       .getState()
  //       .templateToBeEdited.fields.map((field) => ({
  //         position: field.position,
  //         field: field.field.id,
  //       })),
  //     tender: useViewEditTenderStore.getState().tender.id,
  //     name: useVademecumStore.getState().templateToBeEdited.name,
  //     user: user.value,
  //     attachments: selectedDocuments,
  //   };

  //   setRequestStatus("submitting");

  //   let payload = {
  //     section: "View / Edit Tender -> Summary Tab -> request summary",
  //     payload: summaryRequestBody,
  //   }; //data (gets handed back to success fn)

  //   devDebug("This is the payload about to be submitted: ", payload);

  //   saveTemplate(true);

  //   const controller = new AbortController();

  //   apiService.axiosPOST(
  //     `vademecum/vademecums/`, //endpoint
  //     controller.signal, //signal
  //     submitRequestSuccess, //successFn
  //     auth.newErrorHandler, //error AlerterFn
  //     "There was an error submitting the request", //error message
  //     submitRequestError, //errorFn (gets error back)
  //     payload
  //   );
  // };

  return (
    <Modal
      isOpen={fieldSelectionWizardModalVisible}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="tc-screen-width-modal"
      tabIndex="-1"
      zIndex="11"
      toggle={() => {
        setFieldSelectionWizardModalVisible(!fieldSelectionWizardModalVisible);
      }}
    >
      <div className="modal-content">
        <ModalHeader
          tag="h1"
          className="ta-modals-header"
          toggle={() => {
            setFieldSelectionWizardModalVisible(!fieldSelectionWizardModalVisible);
          }}
        >
          {`${createOrEditMode === "create" ? t("New Summary Template") : t("Edit Summary Template")}`}
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column tc-flex-gap-10px tc-text-muted">
            <div className="fw-bold">{t("Assign users to template")}</div>
            <AsyncPaginate
              key={`${templateToBeEdited.id}_0`}
              placeholder={t("User's email")}
              className="w-full"
              additional={defaultAdditional}
              defaultValue={
                templateToBeEdited.users
                  ? templateToBeEdited.users.map((user) => ({
                      label: user,
                      value: user,
                    }))
                  : []
              }
              // defaultInputValue={templateToBeEdited.users}
              // value={tenderingAuthoritySelection.name}
              loadOptions={loadPageOptions}
              isMulti={true}
              onChange={(e) => {
                setUsersAssignedToTemplate(e);
              }}
            />
            <div className="fw-bold">{t("Give your template a name")}</div>
            <Input
              key={`${templateToBeEdited.id}_1`}
              placeholder="Template Name"
              onChange={(e) => {
                setSavingError(null);
                setRequestError(null);
                setTemplateName(e.target.value);
              }}
              type="text"
              defaultValue={templateToBeEdited.name}
              className="mb-5"
            />
            <div className="d-flex">
              <div
                className={`${summaryWizardTab === "search" ? "active" : ""} cursor-pointer user-strategy-tab`}
                onClick={() => {
                  setSummaryWizardTab("search");
                }}
              >
                {t("Search")}
              </div>
              <div
                className={`${summaryWizardTab === "layout" ? "active" : ""} cursor-pointer user-strategy-tab`}
                onClick={() => {
                  setSummaryWizardTab("layout");
                }}
              >
                {t("Layout")}
              </div>
            </div>
            {summaryWizardTab === "search" ? (
              <React.Fragment>
                <div className="fw-bold">
                  {t("Search for and select fields to use in your summary request below: ")}
                </div>
                <Input
                  type="search"
                  placeholder={t("Search fields below")}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setFilteredFields(
                      fields.filter((field) =>
                        field.translations[i18n.language.slice(0, 2)]
                          ? field.translations[i18n.language.slice(0, 2)].display_name
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          : field.translations.en.display_name.toLowerCase().includes(e.target.value.toLowerCase())
                      )
                    );
                  }}
                  defaultValue={search}
                />

                <div className="tc-template-modal-max-height-40vh p-1 d-flex flex-column tc-flex-gap-10px">
                  <FilteredFields
                    filteredFields={filteredFields}
                    setSavingError={setSavingError}
                    setRequestError={setRequestError}
                    i18n={i18n}
                  />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="fw-bold">
                  {t("Drag and drop the below fields to determine the order of the fields in your summary")}
                </div>
                <ReactSortable
                  list={templateToBeEdited.fields.map((x) => ({
                    ...x,
                    chosen: true,
                  }))}
                  setList={(newState) => {
                    newState = newState.map((x, idx) => ({
                      ...x,
                      position: idx,
                    }));
                    setSelectedTemplateFields(newState);
                  }}
                  className="tc-template-modal-max-height-40vh p-1 d-flex flex-column tc-flex-gap-10px"
                >
                  {/* <div className="tc-template-modal-max-height-40vh p-1 d-flex flex-column tc-flex-gap-10px"> */}
                  {templateToBeEdited.fields
                    .slice()
                    .sort((a, b) => {
                      return a.position > b.position;
                    })
                    .map((selectedField, idx) => (
                      <div
                        key={idx}
                        className="d-flex align-items-center tc-flex-gap-10px tc-summary-template-list-item ps-2"
                      >
                        <Icon
                          path={mdiArrowAll}
                          className="tc-edit-template tc_grow_on_hover_icon tc-20px-icon-size"
                          title={t("Move field up or down")}
                          size={0.8}
                        />
                        <div>
                          <div className="fw-bold">
                            {selectedField.field.translations[i18n.language.slice(0, 2)]
                              ? selectedField.field.translations[i18n.language.slice(0, 2)].display_name
                              : selectedField.field.translations[Object.keys(selectedField.field.translations)[0]]
                                  .display_name}
                          </div>
                          <div>
                            {selectedField.field.translations[i18n.language.slice(0, 2)]
                              ? selectedField.field.translations[i18n.language.slice(0, 2)].description
                              : selectedField.field.translations[Object.keys(selectedField.field.translations)[0]]
                                  .description}
                          </div>
                        </div>
                      </div>
                    ))}
                  {/* </div> */}
                </ReactSortable>
              </React.Fragment>
            )}
            <div className="d-flex align-items-center justify-content-between tc-flex-gap-5px ">
              {/*  <div>
                {t(
                  "For extracting custom information, contact us for the enterprise plan"
                )}
              </div>
              <a href="/contact" target="_blank">
                <Button>{t("Contact Us")}</Button>
              </a>*/}
            </div>

            {savingError ? <div className="text-danger">{savingError}</div> : null}
            {requestError ? <div className="tc-formerror">{requestError}</div> : null}
          </div>
        </ModalBody>
        <ModalFooter className="d-flex flex-column flex-sm-row justify-content-between">
          <Button
            className="tc-full-width-small"
            type="button"
            color={`danger`}
            onClick={() => {
              setFieldSelectionWizardModalVisible(false);
              setSummaryTemplateModalVisible(true);
            }}
          >
            {t("Cancel")}
          </Button>
          <div className="d-flex  flex-column flex-sm-row  tc-flex-gap-5px ms-0 ms-sm-auto tc-full-width-small">
            <Button
              className={`${
                templateToBeEdited.fields.length === 0 || templateToBeEdited.name === "" ? "disabled" : ""
              } tc-full-width-small`}
              type="button"
              color={`${
                savingStatus === "saving"
                  ? "secondary"
                  : savingStatus === "success"
                  ? "success"
                  : savingStatus === "error"
                  ? "danger"
                  : "secondary"
              }`}
              onClick={() => saveTemplate()}
            >
              {savingStatus === "saving"
                ? t("Saving...")
                : savingStatus === "success"
                ? t("Success")
                : savingStatus === "error"
                ? t("Error")
                : `${createOrEditMode === "create" ? t("Save Template") : t("Save Changes")}`}
            </Button>
            {/* {submitCapability ? (
              <Button
                className={`${
                  templateToBeEdited.fields.length === 0 ||
                  templateToBeEdited.name === ""
                    ? "disabled"
                    : ""
                } tc-full-width-small`}
                onClick={() => submitRequest()}
                type="button"
                color={`${
                  requestStatus === "submitting"
                    ? "secondary"
                    : requestStatus === "success"
                    ? "success"
                    : requestStatus === "error"
                    ? "danger"
                    : "primary"
                }`}
              >
                {requestStatus === "submitting"
                  ? t("Requesting...")
                  : requestStatus === "success"
                  ? t("Success")
                  : requestStatus === "error"
                  ? t("Error")
                  : `${
                      createOrEditMode === "create"
                        ? t("Save & Request")
                        : t("Edit & Request")
                    }`}
              </Button>
            ) : null} */}
          </div>
        </ModalFooter>
      </div>
    </Modal>
  );
}

const FilteredFields = ({ filteredFields, setSavingError, setRequestError, i18n }) => {
  const [templateToBeEdited, setSelectedTemplateFields] = useVademecumStore((state) => [
    state.templateToBeEdited,
    state.setSelectedTemplateFields,
  ]);

  return filteredFields.map((field, idx) => (
    <div
      key={idx}
      className="d-flex align-items-center tc-flex-gap-10px tc-summary-template-list-item p-1"
      onClick={(e) => {
        setSavingError(null);
        setRequestError(null);
        let isIncluded = false;
        for (let selectedField of templateToBeEdited.fields) {
          if (selectedField.field.id === field.id) {
            isIncluded = true;
            break;
          }
        }
        if (!isIncluded) {
          setSelectedTemplateFields([...templateToBeEdited.fields, { position: 0, field: field }]);
        } else {
          setSelectedTemplateFields(templateToBeEdited.fields.filter((item) => item.field.id !== field.id));
        }
      }}
    >
      <Input
        className="tc-extra-giant-checkbox tc-checkbox-no-margin cursor-pointer tc-small-invisible"
        type="checkbox"
        onChange={() => {}}
        checked={templateToBeEdited.fields
          .map((selectedField) => {
            return selectedField.field.id;
          })
          .includes(field.id)}
      />
      <div>
        <div className="d-flex align-items-center tc-flex-gap-5px">
          <Input
            className="tc-checkbox-no-margin cursor-pointer tc-small-invisible-inverted"
            type="checkbox"
            onChange={() => {}}
            checked={templateToBeEdited.fields
              .map((selectedField) => {
                return selectedField.field.id;
              })
              .includes(field.id)}
          />
          <div className="fw-bold">
            {field.translations[i18n.language.slice(0, 2)]
              ? field.translations[i18n.language.slice(0, 2)].display_name
              : field.translations[Object.keys(field.translations)[0]].display_name}
          </div>
        </div>

        <div>
          {field.translations[i18n.language.slice(0, 2)]
            ? field.translations[i18n.language.slice(0, 2)].description
            : field.translations[Object.keys(field.translations)[0]].description}
        </div>
      </div>
    </div>
  ));
};
// tc-small-invisible
// tc-small-invisible-inverted
