import dayjs from "dayjs";
import i18n from "i18next";
import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import axios from "axios";
import devDebug from "../Helpers/debug";
import { useTenderStrategyStore } from "../Stores/tenderStrategyStore";
import { Button } from "reactstrap";
import TendersClubLogo from "../Components/tendersClubLogo";
import { useNavigate } from "react-router-dom";
import ScreenSpinner from "../Components/ScreenSpinner";
import { useDefaultStrategyStore } from "../Stores/defaultStrategyStore";
import { useComponentsStore } from "../Stores/componentsStore";
import { useTenderAwardsSearchStore } from "../Stores/tenderAwardsSearch";
import { useMapsStore } from "../Stores/mapsStore";
import { useDashboardStore } from "../Stores/dashboardStore";
import { useTabStore } from "../Stores/tabStore";
import { useTenderAwardsStore } from "../Stores/tenderAwardsStore";
import { useTenderSearchStore } from "../Stores/tenderSearch";
import { useCPVStore } from "../Stores/cpvStore";
import { useListUsersStore } from "../Stores/listUsersStore";
import { useCompaniesSearchStore } from "../Stores/companiesSearchStore";
import { useRegisterStore } from "../Stores/registerStore";
import { useVademecumStore } from "../Stores/vademecumStore";
import { useTaskManagementStore } from "../Stores/taskManagementStore";

const localUserKey = `${process.env.REACT_APP_LOCAL_USER_KEY}_${process.env.REACT_APP_VERSION}`;
const authAndFirstTimeKey = `${process.env.REACT_APP_TOKENS_AND_FIRST_TIME_KEY}_${process.env.REACT_APP_VERSION}`;
const tenderSearchAdminTabKey = `${process.env.REACT_APP_TENDER_SEARCH_TAB_ADMIN_KEY}_${process.env.REACT_APP_VERSION}`;
const tenderSearchTabKey = `${process.env.REACT_APP_TENDER_SEARCH_TAB_KEY}_${process.env.REACT_APP_VERSION}`;
// const cookieOptionsKey = `${process.env.REACT_APP_COOKIE_OPTIONS_KEY}_${process.env.REACT_APP_VERSION}`;
const dashboardTabKey = `${process.env.REACT_APP_DASHBOARD_TAB_KEY}_${process.env.REACT_APP_VERSION}`;
const tutorialEnabledKey = `${process.env.REACT_APP_TUTORIAL_ENABLED_KEY}_${process.env.REACT_APP_VERSION}`;
const tenderSearchTableSettingsAdminKey = `${process.env.REACT_APP_TENDER_SEARCH_TABLE_ADMIN_SETTINGS_KEY}_${process.env.REACT_APP_VERSION}`;
const tenderSearchTabAdminKey = `${process.env.REACT_APP_TENDER_SEARCH_TAB_ADMIN_KEY}_${process.env.REACT_APP_VERSION}`;
const tenderSearchTableSettingsKey = `${process.env.REACT_APP_TENDER_SEARCH_TABLE_SETTINGS_KEY}_${process.env.REACT_APP_VERSION}`;
const tenderAwardsSearchTableSettingsKey = `${process.env.REACT_APP_AWARDS_SEARCH_TABLE_SETTINGS_KEY}_${process.env.REACT_APP_VERSION}`;
const colourSchemeKey = `${process.env.REACT_APP_COLOR_SCHEME_KEY}_${process.env.REACT_APP_VERSION}`;
const viewTenderTabStateKey = `${process.env.REACT_APP_VIEW_TENDER_TAB_STATE_KEY}_${process.env.REACT_APP_VERSION}`;
const CPVWeightLocalStorageKey = `${process.env.REACT_APP_VIEW_TENDER_ANALYTICS_CPV_WEIGHT_KEY}_${process.env.REACT_APP_VERSION}`;
const NUTWeightLocalStorageKey = `${process.env.REACT_APP_VIEW_TENDER_ANALYTICS_NUTS_WEIGHT_KEY}_${process.env.REACT_APP_VERSION}`;

let AuthContext = React.createContext();
export function useAuth() {
  return React.useContext(AuthContext);
}
// axios.defaults.withCredentials = false;
// axios.defaults.credentials = "omit";
axios.defaults.baseURL = `${process.env.REACT_APP_BASEURL}/`;

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASEURL}/`,
  // credentials: "omit",
  // withCredentials: false,
});
//CF-Access-Client-Id = "14435f166ae4639c3a591bf3758fc447.access"
//CF-Access-Client-Secret = "171253c587fb8a426429bf7331d7ef8b18fc8353823ed6db04e888ad3fd5b2c8"

const authService = {
  isAuthenticated: false,
  signin(callback) {
    authService.isAuthenticated = true;
    setTimeout(callback, 100);
  },
  signout(callback) {
    authService.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};

export const getTokenAndFirstTimeFromLocalStorage = () => {
  if (localStorage.getItem(authAndFirstTimeKey)) {
    return JSON.parse(localStorage.getItem(authAndFirstTimeKey));
  } else {
    return null;
  }
};

const apiService = {
  //url -> the endpoint
  //signal -> for request cancellation / interruption if navigating away
  //successFn -> whatever needs to happen on success, gets resp & data object
  //errAlertFn -> the hook from alerter context to show errors
  //errMsg -> the message to include for the alerter
  //errFn -> wrap-up function to execute on error, gets data object
  //data -> the data object, can be anything, used for payloads also
  axiosGET(url, signal, successFn, errAlertFn, errMsg, errFn, data) {
    api
      .get(url, {
        signal: signal,
      })
      .then((resp) => {
        successFn(resp.data, data);
      })
      .catch(function (err) {
        const canceled = errAlertFn(errMsg, err, data.section ? data.section : null, data.suppressError);
        if (errFn) {
          if (!canceled) {
            errFn(err, data);
          }
        }
      });
  },
  axiosUnauthGET(url, signal, successFn, errAlertFn, errMsg, errFn, data) {
    axios
      .get(url, {
        signal: signal,
      })
      .then((resp) => {
        successFn(resp.data, data);
      })
      .catch(function (err) {
        const canceled = errAlertFn(errMsg, err, data.section ? data.section : null, data.suppressError);
        if (errFn) {
          if (!canceled) {
            errFn(err, data);
          }
        }
      });
  },
  axiosUnauthPOST(url, signal, successFn, errAlertFn, errMsg, errFn, data) {
    axios
      .post(url, data.payload, {
        signal: signal,
      })
      .then((resp) => {
        successFn(resp.data, data);
      })
      .catch(function (err) {
        const canceled = errAlertFn(errMsg, err, data.section ? data.section : null, data.suppressError);
        if (errFn) {
          if (!canceled) {
            errFn(err, data);
          }
        }
      });
  },
  axiosPOST(url, signal, successFn, errAlertFn, errMsg, errFn, data) {
    api
      .post(url, data.payload, {
        signal: signal,
      })
      .then((resp) => {
        successFn(resp.data, data);
      })
      .catch(function (err) {
        const canceled = errAlertFn(errMsg, err, data.section ? data.section : null, data.suppressError);
        if (errFn) {
          if (!canceled) {
            errFn(err, data);
          }
        }
      });
  },
  axiosPOSTFormData(url, signal, successFn, errAlertFn, errMsg, errFn, data) {
    api
      .post(url, data.formData, {
        signal: signal,
      })
      .then((resp) => {
        successFn(resp.data, data);
      })
      .catch(function (err) {
        const canceled = errAlertFn(errMsg, err, data.section ? data.section : null, data.suppressError);
        if (errFn) {
          if (!canceled) {
            errFn(err, data);
          }
        }
      });
  },
  axiosPATCHFormData(url, signal, successFn, errAlertFn, errMsg, errFn, data) {
    api
      .patch(url, data.formData, {
        signal: signal,
      })
      .then((resp) => {
        successFn(resp.data, data);
      })
      .catch(function (err) {
        const canceled = errAlertFn(errMsg, err, data.section ? data.section : null, data.suppressError);
        if (errFn) {
          if (!canceled) {
            errFn(err, data);
          }
        }
      });
  },
  axiosPATCH(url, signal, successFn, errAlertFn, errMsg, errFn, data) {
    api
      .patch(url, data.payload, {
        signal: signal,
      })
      .then((resp) => {
        successFn(resp.data, data);
      })
      .catch(function (err) {
        const canceled = errAlertFn(errMsg, err, data.section ? data.section : null, data.suppressError);
        if (errFn) {
          if (!canceled) {
            errFn(err, data);
          }
        }
      });
  },
  axiosDELETE(url, signal, successFn, errAlertFn, errMsg, errFn, data) {
    api
      .delete(url, data.payload, {
        signal: signal,
      })
      .then((resp) => {
        successFn(resp.data, data);
      })
      .catch(function (err) {
        const canceled = errAlertFn(errMsg, err, data.section ? data.section : null, data.suppressError);
        if (errFn) {
          if (!canceled) {
            errFn(err, data);
          }
        }
      });
  },
  axiosOPTIONS(url, signal, successFn, errAlertFn, errMsg, errFn, data) {
    api
      .options(url, data.payload, {
        signal: signal,
      })
      .then((resp) => {
        successFn(resp.data, data);
      })
      .catch(function (err) {
        const canceled = errAlertFn(errMsg, err, data.section ? data.section : null, data.suppressError);
        if (errFn) {
          if (!canceled) {
            errFn(err, data);
          }
        }
      });
  },
};

export const removeAllLocalState = (notAuth) => {
  devDebug("logout -> setting unsaved strategy to 0");

  if (!notAuth) {
    localStorage.removeItem(authAndFirstTimeKey);
    localStorage.removeItem(process.env.REACT_APP_LOCAL_USER);
  }

  useTaskManagementStore.getState().resetState();
  useCompaniesSearchStore.getState().resetState();
  useCPVStore.getState().resetState();
  useDashboardStore.getState().resetState();
  useDefaultStrategyStore.getState().resetState();
  useListUsersStore.getState().resetState();
  useMapsStore.getState().resetState();
  useRegisterStore.getState().resetState();
  useTabStore.getState().resetState();
  useTenderAwardsSearchStore.getState().resetState();
  useTenderAwardsStore.getState().resetState();
  useTenderSearchStore.getState().resetState();
  useTenderStrategyStore.getState().resetState();
  useVademecumStore.getState().resetState();
  // useViewEditTenderStore.getState().resetState();
  //localStorage.removeItem(cookieOptionsKey);
  localStorage.removeItem(colourSchemeKey);
  localStorage.removeItem(localUserKey);
  localStorage.removeItem(dashboardTabKey);
  localStorage.removeItem(tenderSearchAdminTabKey);
  localStorage.removeItem(tenderSearchTabKey);
  localStorage.removeItem(tutorialEnabledKey);
  localStorage.removeItem(tenderSearchTableSettingsAdminKey);
  localStorage.removeItem(tenderSearchTabAdminKey);
  localStorage.removeItem(tenderSearchTableSettingsKey);
  localStorage.removeItem(tenderAwardsSearchTableSettingsKey);
  localStorage.removeItem(viewTenderTabStateKey);
  localStorage.removeItem(CPVWeightLocalStorageKey);
  localStorage.removeItem(NUTWeightLocalStorageKey);

  for (var i = 1; i < 99999; i++) {
    window.clearInterval(i);
  }
};

export function AuthProvider({ children }) {
  let [alert, setAlert] = React.useState({});
  let [unauthorised, setUnauthorised] = React.useState(false);
  let [forbidden, setForbidden] = React.useState(false);
  let [showAlert, setShowAlert] = React.useState(false);
  const [isDark] = useComponentsStore((state) => [state.isDark]);
  const navigate = useNavigate();

  let [user, setUser] = React.useState(null);
  let [name, setName] = React.useState(null);
  let [isSuperUser, setIsSuperUser] = React.useState(false);
  let [subscriptionActive, setSubscriptionActive] = React.useState(false);
  let [jwtRefreshed, setJwtRefreshed] = React.useState(false);
  let refreshTokenTimeout;

  const signout = (callback) => {
    return authService.signout(() => {
      setUser(null);
      setIsSuperUser(false);
      setName(null);
      setSubscriptionActive(false);
      stopRefreshTokenTimer();
      removeAllLocalState();
      callback();
    });
  };

  const startRefreshTokenTimer = () => {
    const tokensAndFirstTime = getTokenAndFirstTimeFromLocalStorage();

    if (!tokensAndFirstTime) {
      devDebug("Auth -> No tokens and first time found, returning");
      signout(() => {});
      clearCookies();
      window.location.replace("/login");
      return;
    }

    const jwtToken = JSON.parse(window.atob(tokensAndFirstTime.access.split(".")[1]));

    let expiry = dayjs.unix(jwtToken.exp);
    let now = dayjs();
    let secondsUntilExpiry = expiry.diff(now, "seconds");
    devDebug("secondsUntilExpiry: ", secondsUntilExpiry);
    //let secondsUntilRefresh = 240;
    let secondsUntilRefresh = 30;

    let secondsLeftUntilRefreshNeeded = (secondsUntilRefresh - secondsUntilExpiry) * -1;

    devDebug("secondsLeftUntilRefreshNeeded: ", secondsLeftUntilRefreshNeeded);

    if (secondsLeftUntilRefreshNeeded < 120) {
      devDebug("Auth -> Token expired, refreshing");
      refreshToken();
    } else {
      devDebug(
        `Auth -> refreshTokenSuccess -> Setting JWT refreshed to true, as token is still valid for another ${secondsLeftUntilRefreshNeeded} seconds`
      );
      api.defaults.headers.common["Authorization"] = `JWT ${tokensAndFirstTime.access}`;
      setJwtRefreshed(true);
      refreshTokenTimeout = setTimeout(refreshToken, secondsLeftUntilRefreshNeeded * 1000);
    }
  };

  const refreshTokenSuccess = (resp, data) => {
    const tokensAndFirstTime = getTokenAndFirstTimeFromLocalStorage();
    tokensAndFirstTime.refresh = resp.refresh;
    tokensAndFirstTime.access = resp.access;
    // api.defaults.headers.common["Authorization"] = `JWT ${resp.access}`;
    // setUser(data.authUser);
    // setIsSuperUser(data.authUser?.user?.is_superuser ? true : false);
    // setName(data.authUser?.user?.first_name);
    // setSubscriptionActive(
    //   data.authUser?.user?.subscription?.status === "active" ? true : false
    // );
    updateTokensAndFirstTime(tokensAndFirstTime);
    startRefreshTokenTimer();
    devDebug("Auth -> refreshTokenSuccess -> Setting JWT refreshed to true, with token: ", resp.access);
    setJwtRefreshed(true);
  };
  const refreshTokenError = () => {
    devDebug("Auth -> There was an error refreshing token, setting refreshed to true");
    setJwtRefreshed(true);
  };

  const newErrorHandler = (msg, err, section, suppressError) => {
    devDebug(`Alerter -> Error Handler invoked ${section ? `for ${section}` : ""}`);
    if (err.name === "CanceledError") {
      devDebug("Alerter -> Request was cancelled: " + msg);
      return true;
    } else {
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        devDebug(`Alerter -> Error with response received, msg: ${JSON.stringify(msg)}`);
        devDebug(`Alerter -> data: ${JSON.stringify(err.response.data)}`);
        devDebug(`Alerter -> data: ${JSON.stringify(err.response.data)}`);
        devDebug(`Alerter -> status: ${JSON.stringify(err.response.status)}`);
        devDebug(`Alerter -> headers: ${JSON.stringify(err.response.headers)}`);

        // err.response.data.detail = err.response.data.detail === "Given token not valid for any token type"
        // ? t("You are not authorised to view this page")
        // : e,

        if (err.response.status === 401) {
          removeAllLocalState();
          if (!window.location.toString().includes("login")) {
            window.location.replace("/login");
            return;
          }
        }

        if (err.response.status === 403) {
          setForbidden(true);
        }

        if (err.response.status === suppressError) {
          devDebug("Alerter -> suppressing error: ", suppressError);
          return;
        }

        let errStr = "";
        if (typeof err.response.data === "object") {
          errorString(err.response.data);
          function errorString(errObj) {
            for (let key of Object.keys(errObj)) {
              if (typeof errObj[key] === "string") {
                errStr += errObj[key];
              } else if (Array.isArray(errObj[key])) {
                for (let e of errObj[key]) {
                  errStr += `${key}: ${e} | `;
                }
              } else {
                errorString(errObj[key]);
              }
            }
          }
          errStr = errStr.slice(0, -2);
        } else {
          errStr = err.response.data.toString();
          if (errStr.length > 150) {
            errStr = `${errStr.substring(0, 150)}...`;
          }
          if (errStr.includes("<!DOCTYPE html>")) {
            errStr = i18n.t("Resource not found");
          }
        }

        show({
          message: i18n.t(msg, {
            error: errStr,
          }),
          type: "alert_warning",
        });
      } else if (err.request) {
        devDebug(`Alerter -> Error in request: No / incomplete data from server received, err: ${err}`);
        show({
          message: i18n.t("There was a connection error"),
          type: "alert_warning",
        });
      } else {
        devDebug(`Alerter -> Unhandled exception, msg: ${msg}, error: ${err.toString() ? err.toString() : err}`);
        if (typeof err === "object") {
          let errStr = err.toString();
          if (errStr.length > 150) {
            errStr = `${errStr.substring(0, 150)}...`;
          }
          show({
            message: i18n.t("There was an unknown error", {
              error: errStr,
            }),
            type: "alert_warning",
          });
        } else {
          show({
            message: i18n.t("There was an unknown error"),
            type: "alert_warning",
          });
        }
      }
    }
  };

  const refreshToken = () => {
    const tokensAndFirstTime = getTokenAndFirstTimeFromLocalStorage();
    if (tokensAndFirstTime) {
      const controller = new AbortController();
      return apiService.axiosUnauthPOST(
        `auth/token/refresh/`, //endpoint
        controller.signal, //signal
        refreshTokenSuccess, //successFn
        newErrorHandler, //error AlerterFn
        "Error refreshing access token", //error message
        refreshTokenError, //errorFn (gets error back)
        { payload: { refresh: tokensAndFirstTime.refresh } } //data (gets handed back to success fn)
      );
    }
  };

  let setDetailsAfterSignin = (newUser, callback) => {
    devDebug(`setDetailsAfterSignin -> newUser: `, newUser);
    setUser(newUser);
    setIsSuperUser(newUser?.is_superuser ? true : false);
    setName(newUser?.first_name);
    setSubscriptionActive(newUser?.subscription?.status === "active" ? true : false);
    callback();
    // return <Navigate to="/" replace />;
  };

  //updateUserAndLocalStorage
  //getAuthUserFromLocalStorage
  const updateTokensAndFirstTime = (tokensAndFirstTime) => {
    devDebug("Auth -> Updating tokens and first time: ", tokensAndFirstTime);
    // setUser(tokensAndFirstTime);
    localStorage.setItem(authAndFirstTimeKey, JSON.stringify(tokensAndFirstTime));
  };

  const updateLocalUserDetails = (user) => {
    devDebug("Auth -> Updating local user details: ", user);
    setUser(user);
    localStorage.setItem(localUserKey, JSON.stringify(user));
  };
  const getUserDetailsFromLocalStorage = () => {
    if (localStorage.getItem(localUserKey)) {
      return JSON.parse(localStorage.getItem(localUserKey));
    } else {
      return null;
    }
  };

  if (!user && !jwtRefreshed) {
    const tokensAndFirstTime = getTokenAndFirstTimeFromLocalStorage();
    if (tokensAndFirstTime) {
      const user = getUserDetailsFromLocalStorage();
      if (user) {
        devDebug("Auth -> Found an authUser, starting refresh timer: ", user);
        setUser(user);
        setIsSuperUser(user?.is_superuser ? true : false);
        setName(user?.first_name);
        setSubscriptionActive(user?.subscription?.status === "active" ? true : false);
        startRefreshTokenTimer();
      }
    } else {
      devDebug("Auth -> No auth user found, setting JWT refreshed to true");
      setJwtRefreshed(true);
      clearCookies();
    }
  }

  let signin = () => {
    const tokensAndFirstTime = getTokenAndFirstTimeFromLocalStorage();
    api.defaults.headers.common["Authorization"] = `JWT ${tokensAndFirstTime.access}`;
    return setTimeout(() => {
      authService.signin(() => {
        startRefreshTokenTimer();
      });
    }, 500);
  };

  let setAuthorisation = (access) => {
    api.defaults.headers.common["Authorization"] = `JWT ${access}`;
  };

  const stopRefreshTokenTimer = () => {
    clearTimeout(refreshTokenTimeout);
  };

  let show = (alert) => {
    setAlert(alert);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  let hide = () => {
    setAlert(null);
    setShowAlert(false);
  };

  let value = {
    setAuthorisation,
    apiService,
    user,
    updateTokensAndFirstTime,
    getUserDetailsFromLocalStorage,
    getTokenAndFirstTimeFromLocalStorage,
    updateLocalUserDetails,
    signin,
    setDetailsAfterSignin,
    name,
    setName,
    signout,
    refreshToken,
    isSuperUser,
    subscriptionActive,
    newErrorHandler,
    alert,
    showAlert,
    show,
    hide,
  };

  return jwtRefreshed ? (
    <AuthContext.Provider value={value}>
      {forbidden ? (
        <div className="d-flex tc-screen-filling-message align-items-center justify-content-center tc-text-muted">
          <TendersClubLogo size="normal" colour={isDark ? "light" : "dark"} />
          <div className="heading">{i18n.t("Not Allowed")}</div>
          <div className="body ">
            {i18n.t(
              "You are not allowed to view this content. This is likely due to your subscription level being insufficient."
            )}
            {i18n.t(
              "If your trial expired or you recently canceled your subscription, you will need to re-subscribe before being allowed access."
            )}
          </div>
          <div className="d-flex">
            <Button
              onClick={() => {
                setTimeout(() => {
                  setForbidden(false);
                }, 200);
                navigate("/user_preferences");
              }}
              color="primary"
              className="button-primary me-2"
            >
              {i18n.t("Go to settings")}
            </Button>
            <Button
              onClick={() => {
                setTimeout(() => {
                  setForbidden(false);
                }, 200);
                signout(() => window.location.reload());
              }}
              className="button-primary"
            >
              {i18n.t("Logout")}
            </Button>
          </div>
        </div>
      ) : unauthorised ? (
        <div className="d-flex tc-screen-filling-message align-items-center justify-content-center tc-text-muted">
          <TendersClubLogo size="normal" colour={isDark ? "light" : "dark"} />
          <div className="heading">{i18n.t("Session Expired")}</div>
          <div className="body">{i18n.t("Your login has expired. Please try to login again.")}</div>
          <Button
            onClick={() => {
              setTimeout(() => {
                setUnauthorised(false);
              }, 200);
              navigate("/login");
            }}
            className="button-primary"
          >
            {i18n.t("Login")}
          </Button>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  ) : (
    <ScreenSpinner copy="Loading current user..." />
  );
}

export function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    // the state stored in location is extracted via let from = location.state?.from?.pathname || "/";
    // in inputFields.jsx, which is part of the login page
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export function RequireAdmin({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.isSuperUser) {
    // the state stored in location is extracted via let from = location.state?.from?.pathname || "/";
    // in inputFields.jsx, which is part of the login page
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export function clearCookies() {
  const items = { ...localStorage };
  for (let key of Object.keys(items)) {
    if (key !== "i18nextLng" && !key.includes("cookie")) {
      localStorage.removeItem(key);
    }
  }
}
