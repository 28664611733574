import { mdiOpenInNew } from "@mdi/js";
import Icon from "@mdi/react";
import * as React from "react";
// import dayjs from "dayjs"; //not using dayjs as it doesn't recognise asctime timestamp for some reason
import { useTranslation } from "react-i18next";
import devDebug from "../../../../../Helpers/debug";

export const QueryHistoryItemLogModalItem = ({ item }) => {
  const { t } = useTranslation();
  const [showFullLog, setShowFullLog] = React.useState(false);

  let log = "";
  try {
    log = JSON.parse(item.log);
  } catch {
    log = item?.log;
  }
  return (
    <div className="d-flex flex-column w-full tc-flex-gap-10px">
      <a
        target="_blank"
        className="fw-bold tc-purple-text text-decoration-underline cursor-pointer fs-4 d-flex align-items-center tc-flex-gap-5px mt-2"
        href={item.log_entry_url}
        rel="noreferrer"
      >
        {item?.message.slice(0, 100)}
        <Icon
          path={mdiOpenInNew}
          size={1}
          className="tc_grow_on_hover_icon cursor-pointer"
          title={t("Go to log item (external link)")}
        />
      </a>

      <div className="d-flex flex-column tc-flex-gap-10px">
        <div className="fw-bold">{`Time:`}</div>
        <div className="">{item?.asctime?.slice(0, 19)}</div>
      </div>
      <div className="text-decoration-underline cursor-pointer fw-bold" onClick={() => setShowFullLog(!showFullLog)}>
        {t("Show Full Log")}
      </div>
      {showFullLog ? (
        <div className="d-flex flex-column tc-flex-gap-5px tc-template-modal-max-height-200px p-4">
          {typeof log === "object"
            ? Object.keys(log).map((key) => (
                <div key={key} className="d-flex flex-column tc-flex-gap-10px">
                  <div className="fw-bold">{`${key}: `}</div>
                  <div className="ms-4">{attemptFormatting(key, log[key])}</div>
                </div>
              ))
            : log}
        </div>
      ) : null}
      <hr />
    </div>
  );
};

export const attemptFormatting = (key, val) => {
  devDebug("This is key_: ", key);
  devDebug("This is val: ", val);

  if (!val) {
    return "null";
  }

  if (typeof val === "object") {
    return Object.keys(val).map((key) => (
      <div key={key} className="d-flex flex-column tc-flex-gap-10px">
        <div className="fw-bold">{`${key}: `}</div> <div>{attemptFormatting(key, val[key])}</div>
      </div>
    ));
  }
  if (typeof val === "string") {
    let newVal = val.replaceAll("\\n", "<br/>");
    try {
      const log = JSON.parse(newVal);
      devDebug("non-sliced val was json: ", log);
      return Object.keys(log).map((key) => (
        <div key={key} className="d-flex flex-column tc-flex-gap-10px">
          <div className="fw-bold">{`${key}: `}</div> <div>{attemptFormatting(key, log[key])}</div>
        </div>
      ));
    } catch {
      try {
        devDebug("This is length: ", newVal.length);
        const len = newVal.length;
        const slicedVal = newVal.slice(2, len - 2);
        devDebug("This is val.slice(2): ", slicedVal);
        const log = JSON.parse(slicedVal);
        devDebug("sliced 2 val was JSON: ", log);
        return Object.keys(log).map((key) => (
          <div key={key} className="d-flex flex-column tc-flex-gap-10px">
            <div className="fw-bold">{`${key}: `}</div> <div>{attemptFormatting(key, log[key])}</div>
          </div>
        ));
      } catch {
        devDebug("sliced 2 val was not json");
        try {
          const len = newVal.length;
          const newSlicedVal = newVal.slice(2, len - 2);
          const fixedLog = JSON.parse(newSlicedVal.replace(/\\/g, ""));
          devDebug("sliced 2 with replacement values val was JSON: ", fixedLog);
          return Object.keys(fixedLog).map((key) => (
            <div key={key} className="d-flex flex-column tc-flex-gap-10px">
              <div className="fw-bold">{`${key}: `}</div> <div>{attemptFormatting(key, fixedLog[key])}</div>
            </div>
          ));
        } catch {
          try {
            const len = newVal.length;
            const newSlicedVal = newVal.slice(3, len - 3);
            devDebug("This is val.slice(3): ", newSlicedVal);
            const newLog = JSON.parse(newSlicedVal);
            devDebug("sliced 3 val was JSON: ", newLog);
            return Object.keys(newLog).map((key) => (
              <div key={key} className="d-flex flex-column tc-flex-gap-10px">
                <div className="fw-bold">{`${key}: `}</div> <div>{attemptFormatting(key, newLog[key])}</div>
              </div>
            ));
          } catch {
            devDebug("sliced 3 val was not jason");
            try {
              const len = newVal.length;
              const newSlicedVal = newVal.slice(3, len - 3);
              const fixedLog = JSON.parse(newSlicedVal.replace(/\\/g, ""));
              devDebug("sliced 3 with replacement values val was JSON: ", fixedLog);
              return Object.keys(fixedLog).map((key) => (
                <div key={key} className="d-flex flex-column tc-flex-gap-10px">
                  <div className="fw-bold">{`${key}: `}</div> <div>{attemptFormatting(key, fixedLog[key])}</div>
                </div>
              ));
            } catch {
              devDebug("sliced val was not json: ", newVal);
              return newVal.split("<br/>").map((val, idx) => <div key={idx}>{val}</div>);
            }
          }
        }
      }
    }
  } else {
    return val;
  }
};
